/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/aacomBrowser.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/plugins/aa-browser.js
**/

var BrowserDetect = {
		init : function() {
			this.browser = this.searchString(this.dataBrowser)
					|| "An unknown browser";
			this.version = this.searchVersion(navigator.userAgent)
					|| this.searchVersion(navigator.appVersion)
					|| "an unknown version";
			if ((this.browser == 'Firefox' && this.version < 3.6)
					|| (this.browser == 'Safari' && this.version < 5.0)
					|| (this.browser == 'Chrome' && this.version < 5.0)
					|| (this.browser == 'Explorer' && this.version < 7.0)) {
					jQuery(document).ready(function() {
					  if(document.URL.indexOf("/homePage.do") != -1){
						  jQuery('#failure').show();
					 }
				});
			}			
		},
		searchString : function(data) {
			for ( var i = 0; i < data.length; i++) {
				var dataString = data[i].string;
				var dataProp = data[i].prop;
				this.versionSearchString = data[i].versionSearch
						|| data[i].identity;
				if (dataString) {
					if (dataString.indexOf(data[i].subString) != -1)
						return data[i].identity;
				} else if (dataProp)
					return data[i].identity;
			}
		},
		searchVersion : function(dataString) {
			var index = dataString.indexOf(this.versionSearchString);
			if (index == -1)
				return;
			return parseFloat(dataString.substring(index
					+ this.versionSearchString.length + 1));
		},
		dataBrowser : [ {
			string : navigator.userAgent,
			subString : "Chrome",
			identity : "Chrome"
		},{
			string : navigator.vendor,
			subString : "Apple",
			identity : "Safari",
			versionSearch : "Version"
		},{			
			string : navigator.userAgent,
			subString : "Firefox",
			identity : "Firefox"	
		},{
			string : navigator.userAgent,
			subString : "MSIE",
			identity : "Explorer",
			versionSearch : "MSIE"
		} ]
	};
	BrowserDetect.init();
	
	var agentStr = navigator.userAgent;
	if(((agentStr.indexOf("Trident/6.0") > -1) && (agentStr.indexOf("MSIE 7.0") > -1) ) 
			|| ((agentStr.indexOf("Trident/5.0") > -1) &&  (agentStr.indexOf("MSIE 7.0") > -1)) 
			||((agentStr.indexOf("Trident/4.0") > -1) && (agentStr.indexOf("MSIE 7.0") > -1)) ){
		jQuery(document).ready(function() {
			  if(document.URL.indexOf("/homePage.do") != -1){
				 jQuery('#failure-compatibility').show();
			 }
		});
	}
	else if((agentStr.indexOf("Trident") == -1) && (agentStr.indexOf("MSIE 7.0") > -1)){
				jQuery(document).ready(function() {
					if(document.URL.indexOf("/homePage.do") != -1){
							
						jQuery('#failure').show();
						}
					});
				 }
